import React, { CSSProperties } from 'react';
import { Link } from 'gatsby';
import * as blocStyles from './bloc.module.scss';

interface BlocProps {
  description?: string;
  title?: string;
  src?: string;
  to: string;
  link: string;
  styles?: CSSProperties;
}

export default function Bloc(props: BlocProps): JSX.Element {
  const styles = {
    ...props.styles,
  };

  return (
    <div className={blocStyles.container} style={styles}>
      <Link to={props.to}>
        <div className={blocStyles.content}>
          <div className={blocStyles.title}>{props.title}</div>
          <div className={blocStyles.link}>
            {props.link} {'>'}
          </div>
        </div>
        <div className={blocStyles.imgContainer}>
          <img src={props.src} alt={props.title} />
        </div>
      </Link>
    </div>
  );
}
