import React from 'react';
import { useTranslation } from 'react-i18next';
import Bloc from '../components/bloc/bloc';
import '../styles/global.scss';
import homeCase from '../assets/home_case1.webp';
import homeCase2 from '../assets/home_case2.webp';
import homeCase3 from '../assets/home_case3.webp';
import homeCase4 from '../assets/home_case4.webp';
import '../i18n/i18n';
import Layout from '../components/layout';

export default function Home(): JSX.Element {
  const { t } = useTranslation();
  return (
    <Layout>
      <div className="container">
        <div>
          <div className="title">{t('HOMEPAGE.TITLE')}</div>
          <div className="subtitle">
            <div>{t('HOMEPAGE.SUBTITLE')}</div>
            <div>{t('HOMEPAGE.SUBTITLE_1')}</div>
          </div>
          <div className="separator" />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            flexWrap: 'wrap',
          }}
        >
          <Bloc
            styles={{ backgroundColor: '#FAF3D5' }}
            title={t('HOMEPAGE.USE_CASE_1.TITLE')}
            to="new-signup-experience"
            description={t('HOMEPAGE.USE_CASE_1.DESCRIPTION')}
            src={homeCase}
            link={t('HOMEPAGE.USE_CASE_1.LINK')}
          />
          <Bloc
            styles={{ backgroundColor: '#D1E8F4' }}
            title={t('HOMEPAGE.USE_CASE_2.TITLE')}
            to="redisigning-information-architecture"
            description={t('HOMEPAGE.USE_CASE_2.DESCRIPTION')}
            src={homeCase2}
            link={t('HOMEPAGE.USE_CASE_2.LINK')}
          />
          <Bloc
            styles={{ backgroundColor: '#E7D3C8' }}
            title={t('HOMEPAGE.USE_CASE_3.TITLE')}
            to="/"
            description={t('HOMEPAGE.USE_CASE_3.DESCRIPTION')}
            src={homeCase3}
            link={t('HOMEPAGE.USE_CASE_3.LINK')}
          />
          <Bloc
            styles={{ backgroundColor: '#CDF0D1' }}
            title={t('HOMEPAGE.USE_CASE_4.TITLE')}
            to="/"
            description={t('HOMEPAGE.USE_CASE_4.DESCRIPTION')}
            src={homeCase4}
            link={t('HOMEPAGE.USE_CASE_4.LINK')}
          />
        </div>
      </div>
    </Layout>
  );
}
